<template>
<div class="basic">
  <el-form>
    <el-form-item label="公司简称">
      <el-input v-model="companyInfo.shortName" placeholder="请输入公司简称"/>
    </el-form-item>
    <el-form-item label="公司全称">
      <el-input v-model="companyInfo.fullName" placeholder="请输入公司全称"/>
    </el-form-item>
    <el-form-item label="公司行业">
      <el-cascader
          v-if="loadComplete"
          style="width:100%;"
          :props="industryProp" v-model="industry"></el-cascader>
    </el-form-item>
    <el-form-item label="公司地址">
      <el-input v-model="companyInfo.address" placeholder="请输入公司地址"/>
    </el-form-item>
    <el-form-item label="现居住地">
      <el-cascader
          style="width:100%;"
          :props="areaProp" v-model="cityCode"></el-cascader>
<!--      <div style="display:flex; justify-content: space-between;width:100%;">-->
<!--        <el-select placeholder="省市" v-model="selectedProvince.id" @change="changeProvince" style="margin-right:15px;">-->
<!--          <el-option-->
<!--              v-for="item in provinceList"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id"-->
<!--          />-->
<!--        </el-select> <el-select placeholder="区县" v-model="selectedCity.id" @change="changeCity">-->
<!--        <el-option-->
<!--            v-for="item in cityList"-->
<!--            :key="item.id"-->
<!--            :label="item.name"-->
<!--            :value="item.id"-->
<!--        />-->
<!--      </el-select>-->
<!--      </div>-->
    </el-form-item>
    <el-form-item class="logo_item" label="LOGO">
      <div>
        <div class="tip" style="font-size: 14px;color: #cccccc;">只能上传jpg/png格式文件，文件不能超过500kb</div>
        <el-upload
            action=""
            :show-file-list="false"
            :http-request="handleAvatarUpload"
            >
          <div class="upload_tip" v-show="companyInfo.logoUrl === ''">
            +<br/>上传
          </div>
          <img style="max-width:200px;" v-show="companyInfo.logoUrl!==''" :src="companyInfo.logoUrl"/>
        </el-upload>
      </div>


    </el-form-item>
    <el-form-item label="&nbsp;">
      <div class="button_group">
        <el-button type="primary" round @click="saveData">保存</el-button>
        <el-button round>取消</el-button>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
import {getIndustryList,uploadImage} from "@/api/company/company";
import {getAreaList, getCityList, getProvinceList} from "@/api/user/area";
import {getParentIdArr, jsCopy} from "@/util/util";

export default {
  name: "basic",
  data(){
    let that = this;
    return {
      loading:false,
      selectedProvince:{id:0,name:""},
      selectedCity:{id:0,name:""},
      companyInfo:{},
      loadComplete:false,
      industry:[],
      allIndustryList:[],
      industryProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            that.allIndustryList.map(v=>{
              if(v.parentId === 0 || v.parentId === '0'){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:false
                });
              }
            });
            resolve(nodes);
          }else{
            that.allIndustryList.map(v=>{
              if(v.parentId === node.data.value){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:true
                });
              }
            });
            resolve(nodes);
          }
        }
      },
      cityCode:[],
      areaProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            getProvinceList().then(res => {
              res.data.forEach((v)=>{
                nodes.push({
                  label:v.provinceName,
                  value:v.provinceCode,
                  leaf:false
                });
              })
              resolve(nodes);
            });
          }else if(level === 1){
            getCityList({
              provinceCode:node.data.value
            }).then(res => {
              res.data.forEach(v => {
                nodes.push({
                  label:v.cityName,
                  value:v.cityCode,
                  leaf:false
                });
              })
              resolve(nodes);
            });
          }else if(level === 2){
            getAreaList({
              cityCode:node.data.value
            }).then(res => {
              res.data.forEach(v => {
                nodes.push({
                  label:v.areaName,
                  value:v.areaCode,
                  leaf:true
                });
              });
              resolve(nodes);
            });
          }
        }
      },
    }
  },
  methods:{
    handleAvatarUpload(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      uploadImage(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })
          this.companyInfo.logoUrl = res.data;
        }
      });
    },
    saveData(){
      if(this.industry.length > 0){
        this.companyInfo.industryClassificationId = this.industry[this.industry.length -1];
      }else{
        this.companyInfo.industryClassificationId = "";
      }

      if(this.cityCode.length >0){
        [this.companyInfo.provinceCode,this.companyInfo.cityCode,this.companyInfo.areaCode] = this.cityCode;
      }

      this.$emit("sub_event",{
        act:"update_company_basic",
        info:{
          shortName:this.companyInfo.shortName,
          fullName:this.companyInfo.fullName,
          address:this.companyInfo.address,
          provinceCode:this.cityCode.length>0?this.cityCode[0]:'',
          cityCode:this.cityCode.length>1?this.cityCode[1]:'',
          areaCode:this.cityCode.length>2?this.cityCode[2]:'',
          logoUrl:this.companyInfo.logoUrl,
          industryClassificationId:this.companyInfo.industryClassificationId
        }
      });
    },
    initData(){
      this.companyInfo = jsCopy(this.companyStore.companyInfo);
      if(Object.keys(this.companyInfo).length>0){
        this.cityCode = [this.companyInfo.provinceCode,this.companyInfo.cityCode,this.companyInfo.areaCode];
        getIndustryList().then(res => {
          if(res.code === 0){
            this.allIndustryList = res.data;
            this.loadComplete = true;
            //test//
            // this.companyInfo.industryClassificationId = "55";
            // this.allIndustryList = [
            //   {id:"1",parentId:"0"},
            //   {id:"2",parentId:"0"},
            //   {id:"3",parentId:"0"},
            //   {id:"4",parentId:"0"},
            //   {id:"11",parentId:"1"},
            //   {id:"12",parentId:"1"},
            //   {id:"13",parentId:"1"},
            //   {id:"22",parentId:"2"},
            //   {id:"23",parentId:"3"},
            //   {id:"33",parentId:"22"},
            //   {id:"34",parentId:"22"},
            //   {id:"55",parentId:"33"},
            //   {id:"56",parentId:"33"},
            // ];
            //test//
            this.industry = getParentIdArr(this.companyInfo.industryClassificationId,this.allIndustryList);
          }
        });
      }

    }
  },
  watch:{
    "companyStore.companyInfo":{
      handler(){
        this.initData();
      },
      deep:true
    }
  },
  mounted(){
    this.initData();
  },
  computed:{
    ...mapStores(useCompanyStore)
  },
}
</script>

<style lang="less">
.basic{
  .el-form .logo_item{
    align-items: flex-start;
  }
  .el-form-item__label{
    width:80px;
  }
  .el-upload:focus{
    color:#00b0b1;
  }
  .upload_tip{
    margin-top:10px;
    width: 160px;
    height: 160px;
    border-radius: 5px;
    background-color: #faf9f9;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
  }
}
</style>
