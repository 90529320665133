<template>
<div class="brief">
  <el-form>
    <el-form-item label="一句话介绍">
      <el-input v-model="companyInfo.shortDescription" type="textarea" placeholder="请输入一句话介绍您的公司" :autosize="{minRows:3}"/>
    </el-form-item>
    <el-form-item label="公司简介">
      <el-input v-model="companyInfo.description" type="textarea" placeholder="请输入500字以内的公司介绍"     maxlength="500"
                show-word-limit :autosize="{minRows:3}"/>
    </el-form-item>
    <el-form-item label="企业文化">
      <el-input v-model="companyInfo.enterpriseCulture" type="textarea" placeholder="请输入200字以内的介绍"     maxlength="200"
                show-word-limit :autosize="{minRows:3}"/>
    </el-form-item>
    <el-form-item label="主营业务">
      <el-input v-model="companyInfo.mainBusiness" type="textarea" placeholder="请输入200字以内的介绍"     maxlength="200"
                show-word-limit :autosize="{minRows:3}"/>
    </el-form-item>
    <el-form-item label="&nbsp;">
      <div class="button_group" style="margin-top:50px">
        <el-button type="primary" round @click="submit">保存</el-button>
<!--        <el-button round>取消</el-button>-->
      </div>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
import {jsCopy} from "@/util/util";
export default {
  name: "brief",
  data(){
    return {
      companyInfo:{

      }
    }
  },
  methods:{
    submit(){
      this.$emit("sub_event",{
        act:"update_company_brief",
        info:{
          mainBusiness:this.companyInfo.mainBusiness,
          shortDescription:this.companyInfo.shortDescription,
          description:this.companyInfo.description,
          enterpriseCulture:this.companyInfo.enterpriseCulture,
        }
      });
    }
  },
  mounted() {
    this.companyInfo = jsCopy(this.companyStore.companyInfo);
  },
  computed:{
    ...mapStores(useCompanyStore)
  },
}
</script>

<style lang="less">
.brief{
  .el-form .el-form-item{
    align-items: flex-start;
  }
  .el-form-item__label{
    width:120px;
  }
}
</style>
