<template>
<div class="benefit" style="min-width:1100px;">
  <div class="card_list">
    <div class="benefit_card" @click="editCard(index)" v-for="(item,index) in list" :key="item.welfareName">
      <div class="card_left">
        <img :src="item.welfareLogoUrl" @error="this.src = benefit_travel"/>
      </div>
      <div class="card_right">
        <div class="card_title">{{item.welfareName}}</div>
        <div class="card_content">{{item.description}}</div>
      </div>
    </div>
    <div class="benefit_card card_add" @click="editCard(-1)">
      +<br/>自定义添加
    </div>
  </div>

  <el-dialog
      v-model="showDialog"
      title="自定义福利"
      width="30%"
  >
    <el-form>
      <el-form-item>
        <el-input placeholder="请输入内容" v-model="selectedTitle"/>
      </el-form-item>
      <el-form-item>
        <el-input type="textarea" v-model="selectedBrief" :autosize="{minRows:3}" placeholder="请输入简介"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false" round>取消</el-button>
        <el-button type="primary" @click="updateBenefit" round>
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>

</div>
</template>

<script>
// import benefit_insurance from "@/assets/img/benefit_insurance.svg";
// import benefit_house from "@/assets/img/benefit_house.svg";
// import benefit_medical from "@/assets/img/benefit_medical.svg";
// import benefit_salary from "@/assets/img/benefit_salary.svg";
// import benefit_traffic from "@/assets/img/benefit_traffic.svg";
import benefit_travel from "@/assets/img/benefit_travel.svg";
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
export default {
  name: "benefit",
  data(){
    return {
      benefit_travel,
      showDialog:false,
      selectedIndex:-1,
      selectedTitle:"",
      selectedBrief:"",
      list:[
        // {
        //   id:1,
        //   icon:benefit_insurance,
        //   name:"五险一金",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // },{
        //   id:2,
        //   icon:benefit_travel,
        //   name:"年假",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // },{
        //   id:3,
        //   icon:benefit_salary,
        //   name:"年终奖金",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // },{
        //   id:4,
        //   icon:benefit_traffic,
        //   name:"车补",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // },{
        //   id:5,
        //   icon:benefit_medical,
        //   name:"商业医保",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // },{
        //   id:6,
        //   icon:benefit_house,
        //   name:"住房补贴",
        //   brief:"养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦养老养老Joe饿就哦就佛鳄就哦饭饿就哦"
        // }
      ]
    }
  },
  methods:{
    editCard(index){
      this.selectedIndex = index;
      if(index === -1){
        this.selectedTitle=this.selectedBrief="";
      }else{
        this.selectedTitle = this.list[index].welfareName;
        this.selectedBrief = this.list[index].description;
      }
      this.showDialog = true;
    },
    updateBenefit(){
      if(this.selectedIndex>-1){
        this.list[this.selectedIndex].welfareName = this.selectedTitle;
        this.list[this.selectedIndex].description = this.selectedBrief;
      }else{
        this.list.push({
              welfareName:this.selectedTitle,
              description:this.selectedBrief,
              welfareLogoUrl:this.list.length >0 ? this.list[this.list.length-1].welfareLogoUrl:""
        }
        );
      }
      this.showDialog = false;
      this.$emit("sub_event",{act:"update_company_welfare",list:this.list});
    }
  },
  computed:{
    ...mapStores(useCompanyStore)
  },
  mounted(){
    this.list = this.companyStore.companyInfo.companyWelfareList?JSON.parse(JSON.stringify(this.companyStore.companyInfo.companyWelfareList)):[];
  }
}
</script>

<style lang="less">
  .benefit{
    .card_list{
      display:flex;
      flex-wrap: wrap;
      margin-right:-10px;
      .benefit_card{
        display:flex;
        background-color:#faf9f9;
        border-radius:5px;
        margin-bottom:10px;
        margin-right:10px;
        &:hover{
          background-color:#00b0b1;
          color:#fff;
          .card_right{
            .card_title{
              color:#fff;
            }
            .card_content{
              color:#fff;
            }
          }

        }
        .card_left{
          width:106px;
          display:flex;
          align-items: center;
          justify-content: center;
          img{
            max-width:40px;
          }
        }
        .card_right{
          flex:1;
          padding-right:40px;
          padding-top:30px;
          .card_title{
            font-size: 20px;
            color: #00b0b1;
          }
          .card_content{
            margin-top:5px;
            font-size: 15px;
            color: #cccccc;
          }
        }
      }
    }
    .benefit_card{
      width:355px;
      height:180px;
      cursor:pointer;
    }
    .card_add{
      background-color: #faf9f9;
      display:flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 15px;
      &:hover{
        background-color: #faf9f9;
      }
    }
  }
</style>
