<template>
<div class="page_company_info">
  <div class="inner">
    <div class="side_bar">
      <el-progress
          type="circle"
          stroke-linecap="square"
          :percentage="companyStore.companyInfo.completePercentage"
          color="#00b0b1"
          :stroke-width="12">
        <template #default>
          <span style="font-size:25px;">{{companyStore.companyInfo.completePercentage}}%</span>
        </template>
      </el-progress>
      <div>信息完善度</div>

      <ul>
        <li :class="{cur:curPage === 'basic'}" @click="curPage = 'basic'">公司基本信息</li>
        <li :class="{cur:curPage === 'brief'}" @click="curPage = 'brief'">公司介绍</li>
        <li :class="{cur:curPage === 'benefit'}" @click="curPage = 'benefit'">公司福利</li>
      </ul>
    </div>
    <div class="content_right">
      <basic v-if="curPage === 'basic'" @sub_event="subEvent"></basic>
      <brief v-if="curPage === 'brief'" @sub_event="subEvent"></brief>
      <benefit v-if="curPage === 'benefit'" @sub_event="subEvent"></benefit>
    </div>
  </div>
</div>
</template>

<script>
import basic from "@/companyPages/pages/companyInfo/basic";
import brief from "@/companyPages/pages/companyInfo/brief";
import Benefit from "@/companyPages/pages/companyInfo/benefit";
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
import {jsCopy} from "@/util/util";
import {updateCompany} from "@/api/company/company";

export default {
  name: "companyInfo",
  data(){
    return {
      curPage:"basic",
      companyInfo:{}
    }
  },
  methods:{
    subEvent(param){
      let companyInfo = jsCopy(this.companyStore.companyInfo);
      delete companyInfo.completePercentage;
      if(param.act === "update_company_welfare"){
        companyInfo.companyWelfareList = param.list;
      }else if(param.act === "update_company_basic" || param.act === "update_company_brief"){
        Object.assign(companyInfo,param.info);
      }else{
        return ;
      }
      updateCompany(companyInfo).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"保存成功"
          });
          if(param.act === "update_company_welfare"){
            this.companyStore.$patch((state) => {
              state.companyInfo.companyWelfareList = param.list;
            });
          }else if(param.act === "update_company_basic" || param.act === "update_company_brief"){
            this.companyStore.$patch((state) => {
              Object.assign(state.companyInfo,param.info)
            });
          }
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    }
  },
  components:{
    Benefit,
    basic,brief
  },
  computed:{
    ...mapStores(useCompanyStore)
  },
}
</script>

<style lang="less">
.page_company_info{
  .el-progress{
    margin-top:100px;
  }
  .inner{
    display:flex;
    .content_right{
      margin-left:20px;
      flex:1;
      background-color:#fff;
    }
  }
  .side_bar{
    width:367px;
    background-color:#fff;
    text-align: center;
  }
  .content_right{
    padding:50px;
  }
  ul{
    list-style: none;
    margin:100px auto;
    text-align: center;
    li{
      width:266px;
      height:50px;
      line-height:50px;
      text-align: center;
      border-radius:25px;
      font-size: 16px;
      color: #424858;
      cursor:pointer;
      margin:5px auto;
      &:hover{
        color:#00b0b1;
      }
    }
    .cur{
      background-color:#00b0b1;
      color:#fff!important;
    }
  }
}

</style>
